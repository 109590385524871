$desktop-width-breakpoint: 700px

.how-we-work
  display: flex
  flex-direction: column
  margin: 0 18px
  margin-bottom: 40px
  @media all and (min-width: $desktop-width-breakpoint)
    flex-direction: row
    justify-content: space-between
    max-width: 1342px
    width: 100%
    margin: 0 auto
    padding: 0 20px
    margin-bottom: 72px
  .block
    display: flex
    flex-direction: column
    max-width: 596px
    margin-top: 20px
    @media all and (min-width: $desktop-width-breakpoint)
      margin-top: 40px
      width: 46%
    .image
      img
        width: 100%
    .text-content
      flex: 1
    .title
      font-family: StyreneB-Bold
      font-size: 20px
      margin-top: 12px
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 36px
        margin-top: 20px
    .description
      font-size: 14px
      margin-top: 4px
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 20px
        margin-top: 9px
    .button
      a
        transition: background-color 0.3s
        display: inline-flex
        align-items: center
        justify-content: center
        margin-top: 16px
        font-size: 12px
        min-width: 100%
        min-height: 40px
        border: 1px solid #96A5DE
        @media all and (min-width: $desktop-width-breakpoint)
          min-width: 262px
          font-size: 16px
        &:hover
          background-color: #96A5DE

