$desktop-width-breakpoint: 700px

.shop-by-category
  display: grid
  padding: 0 24px
  margin-top: 20px
  margin-bottom: 18px
  column-gap: 10%
  grid-template-columns: repeat(2, 1fr)
  width: 100%
  row-gap: 10px
  @media all and (min-width: $desktop-width-breakpoint)
    max-width: 1384px
    margin: 0 auto
    margin-top: 60px
    margin-bottom: 60px
    column-gap: 5%
    row-gap: 94px
    grid-template-columns: repeat(3, 1fr)
  .block
    width: 100%
    max-width: 380px
    .image
      img
        width: 100%
    .title
      text-align: center
      font-size: 14px
      margin-top: 8px
      @media all and (min-width: $desktop-width-breakpoint)
        margin-top: 20px
        font-size: 36px
