$desktop-width-breakpoint: 600px

.banners-slider
  .mobile, .desktop
    display: none
  .mobile
    @media all and (max-width: $desktop-width-breakpoint - 1px)
      display: block
  .desktop
    @media all and (min-width: $desktop-width-breakpoint)
      display: block
  .slick-dots
    bottom: 5px
    li
      margin: 0px
      button::before
        content: ''
        opacity: 1
        width: 11px
        height: 11px
        border: 1px solid black
        border-radius: 100%
        margin-left: 4px
    .slick-active
      button::before
        content: ''
        background: black
