$desktop-width-breakpoint: 812px

.shop-by-innovator-slider
  padding: 0 10px
  max-width: 1580px
  margin: 0 auto
  position: relative
  margin-top: 20px
  margin-bottom: 14px
  @media all and (min-width: $desktop-width-breakpoint)
    padding: 0 40px
    margin-top: 35px
    margin-bottom: 40px
  @media all and (min-width: 1140px)
    padding: 0 80px
  .arrow
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    top: 0
    width: 20px
    z-index: 1
    @media all and (min-width: $desktop-width-breakpoint)
      &.desktop
        display: flex
      width: 40px
    @media all and (min-width: 1140px)
      width: 100px
    &.left
      left: 0
    &.right
      right: 0
      img
        transform: rotate(180deg)
  .desktop
    display: none
    @media all and (min-width: $desktop-width-breakpoint)
      display: block
  .mobile
    @media all and (min-width: $desktop-width-breakpoint)
      display: none
