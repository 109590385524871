$desktop-width-breakpoint: 600px
.image
  width: 100%
  img
    width: 100%
.mobile, .desktop
  display: none
.mobile
  @media all and (max-width: $desktop-width-breakpoint - 1px)
    display: block
.desktop
  @media all and (min-width: $desktop-width-breakpoint)
    display: block
.video-wrapper
  position: relative
  .content-wrapper
    position: absolute
    top: 100px
    left: 0
    width: 100%
    padding: 0 20px
    display: flex
    flex-direction: column
    color: white
    z-index: 1
    pointer-events: none
    @media all and (min-width: $desktop-width-breakpoint)
      top: 130px
    .content
      max-width: 1012px
      width: 100%
      margin: 0 auto
      .title
        font-family: StyreneB-Bold
        font-size: 24px
        line-height: 24px
        @media all and (min-width: $desktop-width-breakpoint)
          font-size: 32px
          line-height: 30px
      .description
        margin-top: 24px
        font-family: StyreneB-Light
        font-size: 14px
        @media all and (min-width: $desktop-width-breakpoint)
          margin-top: 30px
      .button
        margin-top: 20px
        max-width: 150px
.video
  video, img
    display: block
    width: 100%
    min-height: 400px
    max-height: 700px
    object-fit: cover
