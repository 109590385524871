$desktop-width-breakpoint: 700px

.home-page
  color: black
  @import "new_home_page/home_page_section"
  @import "new_home_page/best_sellers"
  @import "new_home_page/shop_by_category"
  @import "new_home_page/how_we_work"

  @import "new_home_page/created_for_you"
  @import "new_home_page/safe_science"
  @import "new_home_page/press"
  @import "new_home_page/values"
