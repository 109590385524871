$desktop-width-breakpoint: 700px

.best-sellers
  .link-container
    display: flex
    justify-content: center
    .link
      text-decoration: underline
      font-size: 14px
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 20px
  .best-sellers-products
    display: grid
    grid-template-columns: repeat(2, 1fr)
    column-gap: 10%
    row-gap: 24px
    padding: 0 24px
    margin-top: 20px
    @media all and (min-width: 900px)
      grid-template-columns: repeat(4, 1fr)
      justify-content: space-between
      column-gap: 4%
      padding: 0 40px
      max-width: 1520px
      margin: 0 auto
      margin-top: 40px
    .block
      display: flex
      flex-direction: column
      max-width: 355px
      .image
        img
          width: 100%
      .title
        font-family: StyreneB-Bold
        font-size: 11px
        text-transform: uppercase
        text-align: center
        margin-top: 6px
        flex: 1
        @media all and (min-width: $desktop-width-breakpoint)
          margin-top: 10px
          font-size: 16px
      .description
        text-align: center
        font-size: 9px
        margin-top: 4px
        @media all and (min-width: $desktop-width-breakpoint)
          margin-top: 10px
          font-size: 16px
      .reviews-container
        display: flex
        margin-top: 4px
        font-size: 8px
        align-items: center
        justify-content: center
        @media all and (min-width: $desktop-width-breakpoint)
          font-size: 12px
          margin-top: 10px
        .rating
          color: #9DACE4
          font-family: StyreneB-Bold
          margin-right: 3px
        .stars
          margin-right: 4px
          svg
            margin-right: 1px
            width: 7px
            height: 7px
            @media all and (min-width: $desktop-width-breakpoint)
              width: 10px
              height: 10px
      .button
        margin-top: 5px
        @media all and (min-width: $desktop-width-breakpoint)
          margin-top: 16px
        a
          display: flex
          transition: background-color 0.3s
          align-items: center
          justify-content: center
          width: 100%
          font-size: 8px
          text-align: center
          height: 21px
          background: #9DACE4
          color: #000
          &:hover
            background: #8291C9
          @media all and (min-width: $desktop-width-breakpoint)
            height: 41px
            font-size: 16px
