$desktop-width-breakpoint: 812px

.innovator-card
  width: 100%
  max-width: 300px
  .image
    img
      width: 100%
  .title
    text-align: center
    font-size: 11px
    margin-top: 6px
    margin-bottom: 3px
    font-family: StyreneB-Bold
    @media all and (min-width: $desktop-width-breakpoint)
      margin-top: 10px
      margin-bottom: 8px
      font-size: 16px
  .description
    margin-top: 2px
    font-size: 9px
    text-align: center
    @media all and (min-width: $desktop-width-breakpoint)
      font-size: 12px
      margin-top: 4px
    @media all and (min-width: 1050px)
      font-size: 16px
