$desktop-width-breakpoint: 700px

.press-container
  margin-bottom: 50px
  overflow: hidden
  @media all and (min-width: $desktop-width-breakpoint)
    margin-top: 20px
    margin-bottom: 37px
  .content
    position: relative
    display: flex
    border-top: 1px solid black
    padding-top: 40px
    box-sizing: content-box
    margin: 0 20px
    @media all and (min-width: $desktop-width-breakpoint)
      margin: 0 45px
      padding-top: 34px
    .title
      font-size: 20px
      line-height: 24px
      font-family: StyreneB-Bold
      margin-bottom: 40px
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 32px
        line-height: 32px
        width: 1210px
        margin: 0 auto 46px
  .slider
    display: flex
    width: 100%
    .blocks
      display: flex
      -webkit-overflow-scrolling: touch
      animation: marquee infinite linear
      animation-duration: var(--animation-duration)
      @keyframes marquee
        0%
          transform: translateX(0)
        100%
          transform: translateX(-100%)
      &::-webkit-scrollbar
        display: none
      .block-wrapper
        padding-right: 21px
        z-index: 1
        .block
          min-width: 151px
          height: 151px
          display: flex
          align-items: center
          justify-content: center
          border: 1px solid #9DACE4
          background: white
          padding: 28px
          @media all and (min-width: $desktop-width-breakpoint)
            min-width: 225px
            height: 225px
          img
            width: 100%
            max-width: 100px
            max-height: 100%
      .block-wrapper:nth-child(4n+1)
        .block
          background-color: #9EADE5
      .block-wrapper:nth-child(4n+3)
        .block
          background-color: #ECE83A
