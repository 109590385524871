$desktop-width-breakpoint: 700px
$large-desktop-width-breakpoint: 1500px

.values-container
  width: 100%
  position: relative
  z-index: 0
  &.purple
    background-color: #8D9BDE
  .desktop
    display: none
    @media all and (min-width: $desktop-width-breakpoint)
      display: flex
  .mobile
    @media all and (min-width: $desktop-width-breakpoint)
      display: none
  .clean-vegan-cruelty
    background-position: top -73px left -48px
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: -1
    background-image: url('../../images/patterns/v-pattern-flip.svg')
    opacity: 0.3
    background-position: top -233px left -25px
    background-size: 115%
    @media all and (min-width: $desktop-width-breakpoint)
      background-position: top -90px left 35px
      background-size: 13%
    @media all and (min-width: $large-desktop-width-breakpoint)
      background-position: top -90px left 35px
      background-size: 10%
  .packaging-sustainability
    position: relative
    .background-image
      position: absolute
      width: 100%
      height: 100%
      z-index: -1
      img
        width: 100%
        height: 100%
        object-fit: cover
  .content
    max-width: 870px
    display: flex
    flex-direction: column
    justify-content: center
    margin: 0 20px
    padding: 30px 0
    @media all and (min-width: $desktop-width-breakpoint)
      align-items: center
      margin: 0 auto
    .title
      font-family: 'StyreneB-Bold'
      font-size: 18px
      line-height: 22px
      order: 1
    .subtitle
      font-family: 'StyreneB-Bold'
      font-size: 20px
      line-height: 24px
      padding: 10px 0 5px
      order: 1
      text-transform: uppercase
      @media all and (min-width: $desktop-width-breakpoint)
        padding: 3px 0 25px
        font-size: 22px
    .text
      font-family: 'StyreneB-Light'
      font-size: 14px
      line-height: 20px
      order: 1
      @media all and (min-width: $desktop-width-breakpoint)
        font-size: 18px
        line-height: 22px
        text-align: justify
    .value-tags
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      width: 100%
      order: 3
      @media all and (min-width: $desktop-width-breakpoint)
        width: 260px
        order: 2
      .value-tag
        flex: 0 50%
        display: flex
        align-items: center
        margin-top: 13px
        @media all and (min-width: $desktop-width-breakpoint)
          flex: none
          margin-top: 14px
          flex-direction: column
        .image
          height: 28px
          width: 28px
          display: flex
          justify-content: center
          align-items: center
          @media all and (min-width: $desktop-width-breakpoint)
            height: 40px
            width: 40px
          img
            height: 100%
        .text
          margin-left: 7px
          font-family: StyreneB-Light
          font-weight: 300
          font-size: 14px
          line-height: 20px
          @media all and (min-width: $desktop-width-breakpoint)
            margin: 0
    .learn-more-link
      font-family: 'StyreneB-Light'
      font-size: 12px
      line-height: 14px
      text-decoration: underline
      order: 2
      margin: 8px 0 0
      @media all and (min-width: $desktop-width-breakpoint)
        order: 3
        margin-top: 22px
        margin-bottom: 0
    .learn-more-link:hover
      text-decoration: none
