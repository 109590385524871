.homepage-section
  font-family: StyreneB-Light
  display: flex
  flex-direction: column
  margin-top: 15px
  .homepage-section-title
    font-family: StyreneB-Bold
    font-size: 32px
    text-align: center
    @media all and (min-width: $desktop-width-breakpoint)
      font-size: 48px
